import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { AiOutlineClose } from "react-icons/ai"

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
`
const ImageWrapper = styled.div`
    position: relative;
    margin: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid white;
    border-radius: 8px;
`

const CloseButton = styled.div`
    width: 36px;
    height: 36px;
    border-radius: 99px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    top: -18px;
    right: -18px;
    color: #2B2B2B;
    background: white;
    border: 2px solid #2B2B2B;
    z-index: 99;
`

const PopupImage = () => {
    const data = useStaticQuery(graphql`
        query{
            popupImage: contentfulAsset(contentful_id: {eq: "wkfLJdfBW6n3mxPyg31aF"}) {
                gatsbyImageData
            }
        }
    `)

    const [isVisible, setVisible] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            const lastPopup = window.sessionStorage.getItem("lastPopup")
            if(!lastPopup){
                // Cek apabila sudah 30 menit sejak popup terakhir, maka dia akan munculkan popup (untuk menghindari spam)
                setVisible(true)
            }
        }, 3000)
    })

    const handleClosePopup = () => {
        window.sessionStorage.setItem("lastPopup", Date.now())
        setVisible(false)
    }

    if(!isVisible) return null

    return(
        <Overlay>
            <ImageWrapper>
                <CloseButton onClick={handleClosePopup}><AiOutlineClose size={20}/></CloseButton>
                <GatsbyImage image={data.popupImage.gatsbyImageData} style={{maxWidth: '960px', maxHeight: '640px', aspectRatio: `${data.popupImage.gatsbyImageData.width} / ${data.popupImage.gatsbyImageData.height}`, borderRadius: '8px'}}/>
            </ImageWrapper>
        </Overlay>
    )
}

export default PopupImage