import React from "react";
import Container from "@common/src/components/UI/Container";
import Fade from "react-reveal/Fade";
import Icon from "react-icons-kit";
import { whatsapp } from "react-icons-kit/icomoon/whatsapp";
import { ic_mail_outline } from "react-icons-kit/md/ic_mail_outline";
import SectionWrapper, {
    ContactWrapper
} from "./buttonContact.style";
import { Link } from "gatsby";
import Sticky from "react-stickynode";

const ButtonContact = () => {
    return (
    <SectionWrapper>
        <Container>
            <Link href="https://wa.me/+6285281112343?text=Halo%20GENME%20%21%0AMau%20tanya%20info%20mengenai%20tes%20genetik%20lebih%20lanjut.%20Terima%20kasih" target="_blank">
              <ContactWrapper>
                <Icon
                  icon={whatsapp}
                  className="socials-icon whatsapp"
                  size={26}
                  margin-bottom={20}
                />
              </ContactWrapper>
            </Link>
            <Link href="mailto:info@genme.id" target="_blank">
              <ContactWrapper>
                <Icon
                  icon={ic_mail_outline}
                  className="socials-icon email"
                  size={26}
                />
              </ContactWrapper>
            </Link>
        </Container>
    </SectionWrapper>
  );
}
export default ButtonContact;
