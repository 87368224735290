import styled from 'styled-components';
import { rgba } from 'polished';

const SectionWrapper = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  > div.container {
    display: column;
    margin-bottom: 50%;
  }
`;

export const ContactWrapper = styled.div`
  margin-bottom: 35%;
  display: flex;
  margin-top: 50;
  justify-content: space-between;
  > .socials-icon {
    margin-right: 5%;
    padding: 16px;
    border-radius: 100%;
    background-color: rgba(212, 98, 97, 1);
    color: rgba(251, 239, 239, 1);
    &:hover {
      background-color: rgba(212, 98, 97, 0.1);
      color: rgba(212, 98, 97, 1);
    }
  }
`;

export default SectionWrapper;
